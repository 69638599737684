import { AxiosProgressEvent } from "axios";
import { ContactUs } from "../types/platform/ContactUs";
import { ApiKey, Limit, Profile } from "../types/platform/Profile";
import { Assessment } from "../types/Assessment";
import { Lesson } from "../types/Lesson";
import { Component } from "../types/Component";
import { Syllabus } from "../types/Syllabus";
import { Comment, CommentEvaluation } from "../types/Comment";
import { Consumption } from "../types/Consumption";
import { Unit } from "../types/Unit";
import { Outcome } from "../types/Outcome";
import { Question } from "../types/Quiz";
import { TfcldLessonResponse } from "../types/TfcldTaxonomy";
import { WefLessonResponse } from "../types/WefTaxonomy";
import {
  ServicesRequest,
  ServicesResponse,
} from "../types/platform/ServicesRequest";
import { Upload, UploadSlice, UploadTaskRequest } from "../types/Upload";
import { GetEnhancementsOptions, Reference } from "../types/Reference";
import { teachertoolkitAxios } from "./axios";
import { Glossary } from "../types/Glossary";
import { Slide } from "../types/Presentation";
import { ImageAccessibilityResult } from "../types/ImageAccessibilityResult";
import { Share, ShareForItem, ShareRequest } from "../types/platform/Share";
import { SharedItem } from "../types/platform/SharedItem";
import { fileToBase64 } from "./helpers";
import {
  InteractionQueryParameters,
  InteractionQueryResponse,
} from "../types/Interactions";
import { Resource } from "../types/Resource";

export const submitStudioServicesRequest = async <T,>(
  endpoint: string,
  request: ServicesRequest,
): Promise<ServicesResponse<T>> => {
  const apiRequest: ServicesRequest = { ...request };

  // Convert File to base64 if present
  if (request.file instanceof File) {
    apiRequest.image = await fileToBase64(request.file);
    delete apiRequest.file;
  }

  try {
    const result = await teachertoolkitAxios.post<ServicesResponse<T>>(
      `/v1/${endpoint}`,
      apiRequest,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return result.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

export const getEnhancements = async (
  input: string,
  options?: GetEnhancementsOptions,
): Promise<ServicesResponse<Reference[]>> => {
  if (!input) {
    throw new Error("You must provide some input to search for");
  }

  const {
    ownerIds,
    size = 20,
    thumbnails = false,
    consumeOnResult = false,
    isQuestion = false,
    sessionHash,
    userHash,
    strictMatch = false,
    isTest = true,
  } = options || {};

  if (size > 50) {
    throw new Error("You can't request more than 50 enhancements at a time");
  }

  try {
    const result = await teachertoolkitAxios.get<ServicesResponse<Reference[]>>(
      "/v1/enhancements",
      {
        params: {
          input,
          ownerIds,
          size,
          thumbnails,
          consumeOnResult,
          isQuestion,
          sessionHash,
          userHash,
          strictMatch,
          isTest,
        },
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return result.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

export const getProfile = async (): Promise<Profile> => {
  const result = await teachertoolkitAxios.get<Profile>(`/profile`);
  return result.data;
};

export const getLimits = async (key?: string): Promise<Limit[]> => {
  const result = await teachertoolkitAxios.get<Limit[]>(`/profile/limits`, {
    params: { key },
  });
  return result.data;
};

export const getApiKeys = async (): Promise<ApiKey[]> => {
  const result = await teachertoolkitAxios.get<ApiKey[]>(`/profile/api-keys`);
  return result.data;
};

export const generateApiKey = async (): Promise<ApiKey> => {
  const result = await teachertoolkitAxios.get<ApiKey>(`/profile/api-key`);
  return result.data;
};

export const deleteApiKey = async (id: string): Promise<boolean> => {
  const result = await teachertoolkitAxios.delete<boolean>(
    `/profile/api-key/${id}`,
  );
  return result.data;
};

export const sendContactUs = async (data: ContactUs): Promise<ContactUs> => {
  const result = await teachertoolkitAxios.post<ContactUs>("/contact", data);
  return result.data;
};

export const requestAccountDelete = async (): Promise<boolean> => {
  const result = await teachertoolkitAxios.delete<boolean>("/profile");
  return result.data;
};

export const migrateAccount = async (toEmail: string): Promise<boolean> => {
  const result = await teachertoolkitAxios.get<boolean>("/profile/migrate", {
    params: {
      toEmail,
    },
  });
  return result.data;
};

export const getPublicProfile = async (id: string): Promise<Profile> => {
  const result = await teachertoolkitAxios.get<Profile>(`/profile/${id}`);
  return result.data;
};

export const getSubscriptionPortalUrl = async (): Promise<string> => {
  const result = await teachertoolkitAxios.get<string>(
    `/profile/subscription-portal-url`,
  );
  return result.data;
};

export const getConsumption = async (
  startDate?: Date,
  endDate?: Date,
): Promise<Consumption[]> => {
  const result = await teachertoolkitAxios.get<Consumption[]>(
    "/profile/consumption",
    {
      params: {
        startDate,
        endDate,
      },
    },
  );
  return result.data;
};

export const updateProfile = async (data: Profile): Promise<Profile> => {
  const result = await teachertoolkitAxios.post<Profile>("/profile", data);
  return result.data;
};

export const onboardProfile = async (
  data: Profile,
  adSource?: string,
  adSourceId?: string,
): Promise<Profile> => {
  const result = await teachertoolkitAxios.post<Profile>(
    "/profile/onboard",
    data,
    {
      params: {
        adSource,
        adSourceId,
      },
    },
  );
  return result.data;
};

export const getAssessments = async (): Promise<Assessment[]> => {
  const result = await teachertoolkitAxios.get<Assessment[]>("/assessment");
  return result.data;
};

export const getAssessment = async (id: string): Promise<Assessment> => {
  const result = await teachertoolkitAxios.get<Assessment>(`/assessment/${id}`);
  return result.data;
};

export const addOrUpdateAssessment = async (
  data: Assessment,
): Promise<Assessment> => {
  const result = await teachertoolkitAxios.post<Assessment>(
    "/assessment",
    data,
  );

  return result.data;
};

export const generateAssessment = async (id: string): Promise<Assessment> => {
  const result = await teachertoolkitAxios.get<Assessment>(
    `/assessment/${id}/generate`,
  );
  return result.data;
};

export const deleteAssessment = async (id: string): Promise<boolean> => {
  const result = await teachertoolkitAxios.delete<boolean>(`/assessment/${id}`);
  return result.data;
};

export const getLessons = async (): Promise<Lesson[]> => {
  const result = await teachertoolkitAxios.get<Lesson[]>("/lesson");
  return result.data;
};

export const getLesson = async (id: string): Promise<Lesson> => {
  const result = await teachertoolkitAxios.get<Lesson>(`/lesson/${id}`);
  return result.data;
};

export const getLessonByJoinKey = async (key: string): Promise<Lesson> => {
  const result = await teachertoolkitAxios.get<Lesson>(`/lesson/join/${key}`);
  return result.data;
};

export const regenerateLessonJoinKey = async (id: string): Promise<string> => {
  const result = await teachertoolkitAxios.get<string>(
    `/lesson/${id}/join/regenerate`,
  );
  return result.data;
};

export const generateLessonGlossary = async (id: string): Promise<Glossary> => {
  const result = await teachertoolkitAxios.get<Glossary>(
    `/lesson/${id}/glossary`,
  );
  return result.data;
};

export const generateLessonInstructions = async (
  id: string,
): Promise<string> => {
  const result = await teachertoolkitAxios.get<string>(
    `/lesson/${id}/instructions`,
  );
  return result.data;
};

export const generateLessonSuggestions = async (
  id: string,
  type?: string,
): Promise<Component[]> => {
  const result = await teachertoolkitAxios.get<Component[]>(
    `/lesson/${id}/generate/suggestions`,
    {
      params: {
        type,
      },
    },
  );
  return result.data;
};

export const generateLessonSuggestionImplementation = async (
  id: string,
  component: Component,
): Promise<Component> => {
  const result = await teachertoolkitAxios.post<Component>(
    `/lesson/${id}/suggestion/implement`,
    component,
  );
  return result.data;
};

export const generateLessonPresentationOutline = async (
  id: string,
): Promise<Slide[]> => {
  const result = await teachertoolkitAxios.get<Slide[]>(
    `/lesson/${id}/presentation/outline`,
  );
  return result.data;
};

export const addOrUpdateLesson = async (data: Lesson): Promise<Lesson> => {
  const result = await teachertoolkitAxios.post<Lesson>("/lesson", data);
  return result.data;
};

export const generateQuestionsForLesson = async (
  lessonId: string,
  outcome: string,
  language: string,
): Promise<Question[]> => {
  const result = await teachertoolkitAxios.get<Question[]>(
    `/lesson/${lessonId}/questions`,
    {
      params: {
        input: outcome,
        language,
      },
    },
  );
  return result.data;
};

export const generateLessonImplementationOfTaxonomyRecommendation = async (
  id: string,
  taxonomy: string,
  recommendation: string,
): Promise<Component> => {
  const result = await teachertoolkitAxios.get<Component>(
    `/lesson/${id}/pedagogy/implementation`,
    {
      params: {
        taxonomy,
        recommendation,
      },
    },
  );
  return result.data;
};

export const deleteLesson = async (id: string): Promise<boolean> => {
  const result = await teachertoolkitAxios.delete<boolean>(`/lesson/${id}`);
  return result.data;
};

export const getComments = async (): Promise<Comment[]> => {
  const result = await teachertoolkitAxios.get<Comment[]>("/comment");
  return result.data;
};

export const getComment = async (id: string): Promise<Comment> => {
  const result = await teachertoolkitAxios.get<Comment>(`/comment/${id}`);
  return result.data;
};

export const addOrUpdateComment = async (data: Comment): Promise<Comment> => {
  const result = await teachertoolkitAxios.post<Comment>("/comment", data);
  return result.data;
};

export const deleteComment = async (id: string): Promise<boolean> => {
  const result = await teachertoolkitAxios.delete<boolean>(`/comment/${id}`);
  return result.data;
};

export const generateComment = async (id: string): Promise<Comment> => {
  const result = await teachertoolkitAxios.get<Comment>(
    `/comment/${id}/generate`,
  );
  return result.data;
};

export const generateCommentForPerspective = async (
  id: string,
  commentEvaluation: CommentEvaluation,
): Promise<string> => {
  const result = await teachertoolkitAxios.post<string>(
    `/comment/${id}/generate/perspective`,
    commentEvaluation,
  );
  return result.data;
};

export const generateCommentSummary = async (
  id: string,
  commentEvaluation: CommentEvaluation,
): Promise<string> => {
  const result = await teachertoolkitAxios.post<string>(
    `/comment/${id}/generate/summary`,
    commentEvaluation,
  );
  return result.data;
};

export const getSyllabusOptions = async (
  preferencesOnly?: boolean,
  market?: string,
): Promise<Syllabus[]> => {
  const result = await teachertoolkitAxios.get<Syllabus[]>(
    `/syllabus/options`,
    {
      params: {
        preferencesOnly: preferencesOnly || false,
        market: market || "",
      },
    },
  );
  return result.data;
};

export const getSyllabi = async (): Promise<Syllabus[]> => {
  const result = await teachertoolkitAxios.get<Syllabus[]>("/syllabus");
  return result.data;
};

export const getSyllabus = async (id: string): Promise<Syllabus> => {
  const result = await teachertoolkitAxios.get<Syllabus>(`/syllabus/${id}`);
  return result.data;
};

export const addOrUpdateSyllabus = async (
  data: Syllabus,
): Promise<Syllabus> => {
  const result = await teachertoolkitAxios.post<Syllabus>("/syllabus", data);
  return result.data;
};

export const addOrUpdateOutcomeForSyllabus = async (
  syllabusId: string,
  outcome: Outcome,
): Promise<Outcome> => {
  const result = await teachertoolkitAxios.post<Outcome>(
    `/syllabus/${syllabusId}/outcome`,
    outcome,
  );
  return result.data;
};

export const deleteOutcomeForSyllabus = async (
  syllabusId: string,
  id: string,
): Promise<boolean> => {
  const result = await teachertoolkitAxios.delete<boolean>(
    `/syllabus/${syllabusId}/outcome/${id}`,
  );
  return result.data;
};

export const deleteSyllabus = async (id: string): Promise<boolean> => {
  const result = await teachertoolkitAxios.delete<boolean>(`/syllabus/${id}`);
  return result.data;
};

export const getUnits = async (): Promise<Unit[]> => {
  const result = await teachertoolkitAxios.get<Unit[]>("/unit");
  return result.data;
};

export const getUnit = async (id: string): Promise<Unit> => {
  const result = await teachertoolkitAxios.get<Unit>(`/unit/${id}`);
  return result.data;
};

export const addOrUpdateUnit = async (data: Unit): Promise<Unit> => {
  const result = await teachertoolkitAxios.post<Unit>("/unit", data);
  return result.data;
};

export const generateUnit = async (id: string): Promise<Unit> => {
  const result = await teachertoolkitAxios.get<Unit>(`/unit/${id}/generate`);
  return result.data;
};

export const deleteUnit = async (id: string): Promise<boolean> => {
  const result = await teachertoolkitAxios.delete<boolean>(`/unit/${id}`);
  return result.data;
};

export const generateUnitGlossary = async (id: string): Promise<Glossary> => {
  const result = await teachertoolkitAxios.get<Glossary>(
    `/unit/${id}/glossary`,
  );
  return result.data;
};

export const generateOutcomes = async (
  language: string,
  title: string,
  description?: string,
  sourceInput?: string,
): Promise<Outcome[]> => {
  const result = await submitStudioServicesRequest<Outcome[]>("outcome", {
    outputLanguage: language,
    text: title + " " + description + " " + sourceInput,
    length: 6,
  });

  return result.data as Outcome[];
};

export const getOutcomes = async (
  ownerId?: string,
  syllabusId?: string,
): Promise<Outcome[]> => {
  if (!ownerId || !syllabusId) {
    return [];
  }

  const result = await teachertoolkitAxios.get<Outcome[]>(
    `/syllabus/${ownerId}/${syllabusId}/outcomes`,
  );
  return result.data;
};

export const getOutcomeRecommendations = async (
  input: string,
): Promise<Outcome[]> => {
  const result = await teachertoolkitAxios.get<Outcome[]>(
    `/syllabus/recommended/outcomes`,
    {
      params: {
        input,
      },
    },
  );
  return result.data;
};

export const getWikipediaResources = async (
  query: string,
): Promise<Resource[]> => {
  const result = await teachertoolkitAxios.get<Resource[]>(
    "/resources/wikipedia",
    {
      params: {
        query,
      },
    },
  );
  return result.data;
};

export const getBoclipsVideos = async (query: string): Promise<Resource[]> => {
  const result = await teachertoolkitAxios.get<Resource[]>(
    "/resources/boclips",
    {
      params: {
        query,
      },
    },
  );
  return result.data;
};

export const getBoclipsTokenForLesson = async (id: string): Promise<string> => {
  const result = await teachertoolkitAxios.get<string>(
    `/lesson/${id}/boclips`,
    {
      params: {
        id,
      },
    },
  );
  return result.data;
};

export const getBoclipsTokenForSharedLesson = async (
  joinKey: string,
): Promise<string> => {
  const result = await teachertoolkitAxios.get<string>(
    `/lesson/join/${joinKey}/boclips`,
  );
  return result.data;
};

export const getWileyOnlineResources = async (
  query: string,
): Promise<Resource[]> => {
  const result = await teachertoolkitAxios.get<Resource[]>(
    "/resources/wileyonlinelibrary",
    {
      params: {
        query,
      },
    },
  );
  return result.data;
};

export const getBingResources = async (query: string): Promise<Resource[]> => {
  const result = await teachertoolkitAxios.get<Resource[]>("/resources/bing", {
    params: {
      query,
    },
  });
  return result.data;
};

export const getTfcldEvaluationForLesson = async (
  lessonId: string,
): Promise<TfcldLessonResponse> => {
  const result = await teachertoolkitAxios.get<TfcldLessonResponse>(
    `/lesson/${lessonId}/pedagogy/21cld`,
  );

  return result.data;
};

export const getWefEvaluationForLesson = async (
  lessonId: string,
): Promise<WefLessonResponse> => {
  const result = await teachertoolkitAxios.get<WefLessonResponse>(
    `/lesson/${lessonId}/pedagogy/wef`,
  );

  return result.data;
};

export const uploadSomething = async (
  file: File,
  reference?: string,
  isbn?: string,
  language?: string,
  onProgress?: (progressEvent: AxiosProgressEvent) => void,
): Promise<Upload> => {
  const formData = new FormData();
  formData.append("File", file);
  formData.append("Reference", reference || "");
  formData.append("Isbn", isbn || "");
  formData.append("Language", language || "");

  const response = await teachertoolkitAxios.put<Upload>(`/upload`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: onProgress,
  });

  return response.data;
};

export const getUploads = async (): Promise<Upload[]> => {
  const result = await teachertoolkitAxios.get<Upload[]>("/upload");
  return result.data;
};

export const getUploadDownloadUrl = async (id: string): Promise<string> => {
  const result = await teachertoolkitAxios.get<string>(
    `/upload/${id}/download`,
  );
  return result.data;
};

export const getUpload = async (id: string): Promise<Upload> => {
  const result = await teachertoolkitAxios.get<Upload>(`/upload/${id}`);
  return result.data;
};

export const getUploadInteractions = async (
  queryParameters: InteractionQueryParameters,
): Promise<InteractionQueryResponse> => {
  const result = await teachertoolkitAxios.get<InteractionQueryResponse>(
    `/upload/interactions`,
    {
      params: queryParameters,
    },
  );
  return result.data;
};

export const getUploadEnhancementRequests = async (
  queryParameters: InteractionQueryParameters,
): Promise<InteractionQueryResponse> => {
  const result = await teachertoolkitAxios.get<InteractionQueryResponse>(
    `/upload/enhancement-requests`,
    {
      params: queryParameters,
    },
  );
  return result.data;
};

export const getRegeneratedUploadData = async (id: string): Promise<Upload> => {
  const result = await teachertoolkitAxios.get<Upload>(
    `/upload/${id}/regenerate`,
  );
  return result.data;
};

export const getUploadThumbnails = async (
  id: string,
  count: number,
): Promise<string[]> => {
  const result = await teachertoolkitAxios.get<string[]>(
    `/upload/${id}/thumbnails`,
    {
      params: {
        count,
      },
    },
  );
  return result.data;
};

export const getUploadStatus = async (id: string): Promise<Upload> => {
  const result = await teachertoolkitAxios.get<Upload>(`/upload/${id}/status`);
  return result.data;
};

export interface PagedSlicesResponse {
  slices: UploadSlice[];
  continuationToken: string | undefined;
  totalFilteredCount: number;
  totalUnfilteredCount: number;
}

export interface UploadSlicesFilter {
  searchText?: string;
  imageFilter?: "all" | "onlyImages" | "noImages";
  accessibilityFilter?: "all" | "withDetails" | "withoutDetails";
  outcomeFilter?: "all" | "withOutcomes" | "withoutOutcomes";
  alignmentFilter?: "all" | "withAlignments" | "withoutAlignments";
  suggestionFilter?: "all" | "withSuggestions" | "withoutSuggestions";
  contentFilter?: "all" | "withContent" | "withoutContent";
  enabledFilter?: "all" | "enabledOnly" | "disabledOnly";
  pageSize: number;
  continuationToken?: string;
}

export const getUploadSlices = async (
  id: string,
  filter: UploadSlicesFilter,
): Promise<PagedSlicesResponse> => {
  const parameters = new URLSearchParams({
    pageSize: filter.pageSize.toString(),
    ...(filter.continuationToken && {
      continuationToken: filter.continuationToken,
    }),
    ...(filter.searchText && { searchText: filter.searchText }),
    ...(filter.imageFilter && { imageFilter: filter.imageFilter }),
    ...(filter.accessibilityFilter && {
      accessibilityFilter: filter.accessibilityFilter,
    }),
    ...(filter.outcomeFilter && { outcomeFilter: filter.outcomeFilter }),
    ...(filter.alignmentFilter && { alignmentFilter: filter.alignmentFilter }),
    ...(filter.suggestionFilter && {
      suggestionFilter: filter.suggestionFilter,
    }),
    ...(filter.contentFilter && { contentFilter: filter.contentFilter }),
    ...(filter.enabledFilter && { enabledFilter: filter.enabledFilter }),
  });
  const result = await teachertoolkitAxios.get<PagedSlicesResponse>(
    `/upload/${id}/slices`,
    { params: parameters },
  );
  return result.data;
};

export const getUploadSlice = async (
  uploadId: string,
  sliceId: string,
): Promise<UploadSlice> => {
  const result = await teachertoolkitAxios.get<UploadSlice>(
    `/upload/${uploadId}/slice/${sliceId}`,
  );
  return result.data;
};

export const deleteUploadSlice = async (
  id: string,
  sliceId: string,
): Promise<boolean> => {
  const result = await teachertoolkitAxios.delete<boolean>(
    `/upload/${id}/slice/${sliceId}`,
  );
  return result.data;
};

export const getSliceImage = async (
  id: string,
  sliceId: string,
): Promise<string> => {
  const result = await teachertoolkitAxios.get<string>(
    `/upload/${id}/slice/${sliceId}/image`,
  );
  return result.data;
};

export const getSliceImageAnalysis = async (
  id: string,
  sliceId: string,
): Promise<ImageAccessibilityResult> => {
  const result = await teachertoolkitAxios.get<ImageAccessibilityResult>(
    `/upload/${id}/slice/${sliceId}/image/analysis`,
  );
  return result.data;
};

export const deleteUpload = async (id: string): Promise<boolean> => {
  const result = await teachertoolkitAxios.delete<boolean>(`/upload/${id}`);
  return result.data;
};

export const updateUpload = async (data: Upload): Promise<Upload> => {
  const result = await teachertoolkitAxios.post<Upload>("/upload", data);
  return result.data;
};

export const updateUploadSlice = async (
  data: UploadSlice,
): Promise<UploadSlice> => {
  const result = await teachertoolkitAxios.post<UploadSlice>(
    "/upload/slice",
    data,
  );
  return result.data;
};

export const addUploadSlice = async (
  uploadId: string,
  data: UploadSlice,
): Promise<UploadSlice> => {
  const result = await teachertoolkitAxios.put<UploadSlice>(
    `/upload/${uploadId}/slice`,
    data,
  );
  return result.data;
};

export const getUploadReferences = async (id: string): Promise<Reference[]> => {
  const result = await teachertoolkitAxios.get<Reference[]>(
    `/upload/${id}/references`,
  );
  return result.data;
};

export const runUploadTask = async (
  taskDetails: UploadTaskRequest,
): Promise<Upload> => {
  const result = await teachertoolkitAxios.post(
    `/upload/${taskDetails.uploadId}/task/${taskDetails.task}`,
    {
      params: {
        language: taskDetails.language || "en",
      },
      body: taskDetails.syllabus,
    },
  );

  return result.data;
};

export const getReferences = async (id: string): Promise<Reference[]> => {
  const result = await teachertoolkitAxios.get<Reference[]>(
    `/upload/references/${id}`,
  );
  return result.data;
};

export const getShares = async (id: string): Promise<Share[]> => {
  const result = await teachertoolkitAxios.get<Share[]>(`/sharing/${id}`);
  return result.data;
};

export const getSharedWithMe = async (): Promise<ShareForItem[]> => {
  const result =
    await teachertoolkitAxios.get<ShareForItem[]>(`/sharing/withme`);
  return result.data;
};

export const getSharedByMe = async (): Promise<ShareForItem[]> => {
  const result = await teachertoolkitAxios.get<ShareForItem[]>(`/sharing`);
  return result.data;
};

export const shareSomething = async (
  shareRequest: ShareRequest,
): Promise<Share[]> => {
  const result = await teachertoolkitAxios.put<Share[]>(
    `/sharing/${shareRequest.type}/${shareRequest.id}/${shareRequest.mode}`,
    shareRequest.emails,
  );
  return result.data;
};

export const stopSharing = async (
  ownerId: string,
  itemId: string,
  shareId: string,
): Promise<boolean> => {
  const result = await teachertoolkitAxios.delete(
    `/sharing/${ownerId}/${itemId}/${shareId}`,
  );
  return result.data;
};

export const getSharedItem = async (
  ownerId: string,
  id: string,
  accessKey?: string,
): Promise<SharedItem> => {
  const result = await teachertoolkitAxios.get(
    `/sharing/shared/${ownerId}/${id}`,
    {
      params: { accessKey },
    },
  );
  return result.data;
};
